import styles from './styles.module.css';

const MegaBannerDerecha = (props) => {

    const {
        customId
    } = {...props}

    return (
        <div 
            data-adtype="lateralmega" 
            className={`${styles['megabanner-derecha']} ad-slot`} 
            id={`${customId || 'megaBanner-derecha'}`} 
        />
    )
}

export default MegaBannerDerecha;