import React, { useEffect, useState } from 'react';
import './styles.module.css';
import Head from 'next/head';
import { useAppContext } from '@pagegrowler_nextjs';
import Header from '@/shared/components/Header';
import Footer from '@/shared/components/Footer';
import MetaTags from '@/shared/components/MetaTags';
import MegaBannerDerecha from '@/shared/components/MegaBannerDerecha';
import Chartbeat from '@/shared/components/Chartbeat';
// import PAGEGROWLER_PROPS from 'custom-pagegrowler';

function TemplateBuilder(props) {
    const pg = useAppContext();
    const Pagegrowler = pg.pagegrowler;
    Pagegrowler.setWidgetsLayout(props.widgetList);
    
    const description = 'Noticias de Santa Fe, Argentina y El Mundo. El Litoral de Santa Fe. Noticias actualizadas las 24 horas.';
    let title = props.landing && props.landing.title ? props.landing.title : '';
    let url = props.landing && props.landing.url ? props.landing.url : '';

    const menues = props.menu ? props.menu : [];
    const [open, setOpen] = useState(false);
    let dataLDJson =  {
        "@context": "https://schema.org",
        "@type": "Organization",
        "name": "Diario El Litoral",
        "url": "https://www.ellitoral.com",
        "logo": {
          "@type": "ImageObject",
          "url": `${process.env.NEXT_PUBLIC_DOMAIN_URL}/img/header_logoellitoral.svg`,
          "width": 439,
          "height": 60
        },
        "sameAs": ["https://www.facebook.com/ellitoralcom", "https://instagram.com/ellitoral", "https://twitter.com/ellitoral", "https://plus.google.com/+EllitoralArgentina"]
      };

    useEffect(() => {
        document.title = title + ' - El Litoral';
     }, []);

    const getUrl = () => {
        return url.includes("/layout-necrologicas")
            ? "/servicios/necrologicas"
            : url;
    }
    
    return (
        <>
            <Head>
                <title>{title}</title>
                <script type="application/ld+json" dangerouslySetInnerHTML={{ __html: JSON.stringify(dataLDJson) }} /> 
                {!Pagegrowler.isBuilderMode && <meta httpEquiv="refresh" content="600" />}
                <meta name="description" property="description" content={description} />
                <meta name="og:url" property="og:url" content={'https://www.ellitoral.com' + getUrl() } />
                <meta name="og:type" property="og:type" content="article" />
                <meta name="og:title" property="og:title" content={title} />
                <meta name="og:description" property="og:description" content={description} />
                <link rel="canonical" href={process.env.NEXT_PUBLIC_DOMAIN_URL + getUrl()} />
            </Head>
            <MetaTags metas={props.meta}/>
            <Header menues={menues} isOpenSidePanel={(open) => setOpen(open)} />
            <Chartbeat sections={props.properties} />

            <div>
                {Pagegrowler.generateDOM(props, props.preview)}
            </div>
            <Footer />
            {!props.preview && <MegaBannerDerecha customId={`lateralmega`}/>}
        </>
    )
}

export default TemplateBuilder;
